import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/components/Flex/Flex.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/components/Group/Group.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Input"] */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/components/Input/Input.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Space"] */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/components/Space/Space.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/components/Stack/Stack.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/components/Text/Text.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["UnstyledButton"] */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/components/UnstyledButton/UnstyledButton.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/core/Box/Box.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+dropzone@7.8.1_@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@1_tpa4rjywdooiq64aac2f36tree/node_modules/@mantine/dropzone/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Notifications"] */ "/app/node_modules/.pnpm/@mantine+notifications@7.8.1_@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+re_nbuncjwh7x2ughgcvisszgrpsu/node_modules/@mantine/notifications/esm/Notifications.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["cleanNotifications","cleanNotificationsQueue","createNotificationsStore","hideNotification","notifications","notificationsStore","showNotification","updateNotification","updateNotificationsState","useNotifications"] */ "/app/node_modules/.pnpm/@mantine+notifications@7.8.1_@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+re_nbuncjwh7x2ughgcvisszgrpsu/node_modules/@mantine/notifications/esm/notifications.store.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+nprogress@7.8.1_@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+react@_ibxfmh4izdlg3rr4z2aqxa2gle/node_modules/@mantine/nprogress/styles.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mantine+notifications@7.8.1_@mantine+core@7.8.1_@mantine+hooks@7.8.1_react@18.3.1__@types+re_nbuncjwh7x2ughgcvisszgrpsu/node_modules/@mantine/notifications/styles.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-noto-serif\",\"src\":[{\"path\":\"./../../public/fonts/notoserif/NotoSerif-Thin.woff2\",\"weight\":\"100\",\"style\":\"normal\"},{\"path\":\"./../../public/fonts/notoserif/NotoSerif-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./../../public/fonts/notoserif/NotoSerif-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./../../public/fonts/notoserif/NotoSerif-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./../../public/fonts/notoserif/NotoSerif-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"./../../public/fonts/notoserif/NotoSerif-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"notoSerif\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-seed-sans\",\"src\":[{\"path\":\"./../../public/fonts/seedsans/LINESeedSansTH_W_Th.woff2\",\"weight\":\"100\",\"style\":\"normal\"},{\"path\":\"./../../public/fonts/seedsans/LINESeedSansTH_W_Rg.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./../../public/fonts/seedsans/LINESeedSansTH_W_Bd.woff2\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"seedSans\"}");
;
import(/* webpackMode: "eager" */ "/app/public/bot.png");
;
import(/* webpackMode: "eager" */ "/app/public/line_branding.png");
;
import(/* webpackMode: "eager" */ "/app/public/partcipant.png");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AdminImageMessageBubble/AdminImageMessageBubble.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AdminVideoMessageBubble/AdminVideoMessageBubble.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/BotCard/BotCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/BotProfileTab/BotProfileTab.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Chat/Chat.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/EnabledText/EnabledText.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/LoaderCentered/LoaderCentered.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MagicBorder/MagicBorder.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/NavigationBar/NavigationBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ParticipantImageMessageBubble/ParticipantImageMessageBubble.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ParticipantVideoMessageBubble/ParticipantVideoMessageBubble.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/RouterTransition/RouterTransition.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Sidebar/Sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Tab/Tab.tsx");
